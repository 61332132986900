import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { useMessagesQueue } from '@atlasmic/shared/hooks/messages/useMessagesQueue'
import { useMessageCreated } from '@atlasmic/shared/hooks/messages/useMessageCreated'
import { useConversationCreated } from '@atlasmic/shared/hooks/conversations/useConversationCreated'
import { useConnectionStatus } from '~hooks/connectionStatuses/useConnectionStatus'
import { onError } from '~utils/errors/onError'

export const AuthenticatedGlobalHooks = () => {
  const { addToast } = useToast()

  useMessagesQueue({
    onError: onError({ addToast }),
  })
  useMessageCreated({
    onError: onError({ addToast }),
  })
  useConversationCreated({
    onError: onError({ addToast }),
  })

  useConnectionStatus()

  return null
}

import { ApolloLink } from '@apollo/client/link/core'
import { authLink } from './authLink'
import { httpLink } from './httpLink'
import { errorLink } from './errorLink'

export const fullHttpLink = (opts) => (
  ApolloLink.from([
    authLink(opts),
    errorLink,
    httpLink(opts),
  ])
)

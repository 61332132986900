import React, { forwardRef } from 'react';
import { Box } from '~components/Box'

export const Icon = forwardRef(function Icon({
  size = 3,
  ...rest
}, ref) {
  return (
    <Box
      height={size}
      width={size}
      {...rest}
    />
  )
})

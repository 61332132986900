import axios from 'axios'
import { getRefreshToken } from './getRefreshToken'

let singleton = null

export const getNewAccessToken = (opts) => () => {
  if (!singleton) {
    return (singleton = (async () => {
      const accessTokenObject = opts.accessTokenProps.getAccessTokenObject()

      if (!accessTokenObject) {
        throw 'UNAUTHORIZED'
      }

      let newToken

      try {
        newToken = await axios({
          url: `${process.env.NEXT_PUBLIC_API_AUTH_URL}/oauth/token`,
          method: 'post',
          data: {
            client_id: process.env.NEXT_PUBLIC_AUTH_CLIENT_ID,
            grant_type: 'refresh_token',
            refresh_token: getRefreshToken(opts),
          },
        })
      } catch (e) {
        throw 'UNAUTHORIZED'
      }

      opts.accessTokenProps.setAccessToken(newToken.data)
      opts.updateWebSockets()

      singleton = null
      return newToken.data.accessToken
    })())
  }

  return singleton
}

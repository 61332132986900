import { useState } from 'react'
import { Provider } from './Context'

export const IsSignupCompletedModalShownProvider = ({ children }) => {
  const [isSignupCompletedModalShown, setIsSignupCompletedModalShown] =
    useState(false)

  return (
    <Provider
      value={{
        isSignupCompletedModalShown,
        setIsSignupCompletedModalShown,
      }}
    >
      {children}
    </Provider>
  )
}

import { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Provider } from './Context'
import { name } from './lib/name'
import { currentWorkspaceId } from './lib/currentWorkspaceId'
import { options } from './lib/options'

export const CurrentWorkspaceIdProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies([name])

  return (
    <Provider
      value={{
        currentWorkspaceId: currentWorkspaceId({ cookies }),
        setCurrentWorkspaceId: (token) =>
          setCookie(name, JSON.stringify(token), options()),
        removeCurrentWorkspaceId: () => removeCookie(name, options()),
      }}
    >
      {children}
    </Provider>
  )
}

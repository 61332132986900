import { topCookieDomain } from '@atlasmic/shared/utils/topCookieDomain'

export const options = () => {
  const domain = topCookieDomain()

  return {
    secure: !!domain,
    // 30 days
    maxAge: 60 * 60 * 24 * 30,
    path: '/',
    ...(domain ? { domain } : {}),
  }
}

import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'
import NextApp from 'next/app'
import Router from 'next/router'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/node'
import { CookiesProvider, Cookies } from 'react-cookie'
import { ApolloProvider } from '@apollo/client'
import dayjs from 'dayjs'
import { AmendableProvider } from '@amendable/core'
import { NewMessagesProvider } from '@atlasmic/shared/components/messages/NewMessagesProvider'
import { NewConversationProvider } from '@atlasmic/shared/components/conversations/NewConversationProvider'
import { IntervalProvider } from '@atlasmic/shared/components/intervals/IntervalProvider'
import { ToastProvider } from '@atlasmic/shared/components/toasts/ToastProvider'
import { WebSocketsUpdatedAtProvider } from '@atlasmic/shared/components/webSockets/WebSocketsUpdatedAtProvider'
import { CurrentTimeProvider } from '@atlasmic/shared/components/times/CurrentTimeProvider'
import { SubscriptionClientProvider } from '@atlasmic/shared/components/subscriptionClients/SubscriptionClientProvider'
import { SubscriptionClientEventsProvider } from '@atlasmic/shared/components/subscriptionClients/SubscriptionClientEventsProvider'
import { useSubscriptionClient } from '@atlasmic/shared/hooks/subscriptionClients/useSubscriptionClient'
import { useSubscriptionClientEvents } from '@atlasmic/shared/hooks/subscriptionClients/useSubscriptionClientEvents'
import { useUpdateWebSockets } from '@atlasmic/shared/hooks/webSockets/useUpdateWebSockets'
import { ModalProvider } from '~components/ModalProvider'
import { IsSignupCompletedModalShownProvider } from '~components/signup/IsSignupCompletedModalShownProvider'
import { CurrentWorkspaceIdProvider } from '~components/workspaces/CurrentWorkspaceIdProvider'
import { useModal } from '~hooks/modals/useModal'
import { useApollo } from '~hooks/apollo/useApollo'
import { useAccessToken } from '~hooks/auth/useAccessToken'
import { useForegroundNotifications } from '~hooks/notifications/useForegroundNotifications'
import { page } from '~analytics/page'
import 'theme/index.css'
import resolvers from '~theme/resolvers'
import { isBrowser } from '~utils/misc/isBrowser'
import { useCurrentAgent } from '@atlasmic/shared/hooks/agents/useCurrentAgent'
import { AuthenticatedGlobalHooks } from '~components/AuthenticatedGlobalHooks'

const Content = ({ Component, pageProps, err }) => {
  const { modal } = useModal()
  const { currentAgent } = useCurrentAgent({
    fetchPolicy: 'cache-only',
  })

  useForegroundNotifications()

  return (
    <>
      <Component {...pageProps} err={err} />
      {modal}
      {currentAgent && <AuthenticatedGlobalHooks />}
    </>
  )
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

const App = ({ cookies, initialCurrentTime, ...rest }) => {
  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_HOTJAR_ID) return

    hotjar.initialize(
      process.env.NEXT_PUBLIC_HOTJAR_ID,
      process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION
    )
  }, [])

  useEffect(() => {
    page({
      pathname: window.location.pathname,
      skip: ['atlasmic'],
    })

    const handleRouteChange = (pathname) =>
      page({
        pathname,
      })

    Router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return (
    <CurrentTimeProvider initialCurrentTime={initialCurrentTime}>
      <CookiesProvider cookies={isBrowser() ? undefined : cookies}>
        <CurrentWorkspaceIdProvider>
          <WebSocketsUpdatedAtProvider>
            <SubscriptionClientProvider>
              <SubscriptionClientEventsProvider>
                <TopContent {...rest} />
              </SubscriptionClientEventsProvider>
            </SubscriptionClientProvider>
          </WebSocketsUpdatedAtProvider>
        </CurrentWorkspaceIdProvider>
      </CookiesProvider>
    </CurrentTimeProvider>
  )
}

const TopContent = ({ Component, pageProps, err }) => {
  const router = useRouter()
  const accessTokenProps = useAccessToken()
  const { setSubscriptionClient } = useSubscriptionClient()
  const { createSubscriptionClientEvent } = useSubscriptionClientEvents()
  const { updateWebSockets } = useUpdateWebSockets()

  const apollo = useApollo({
    pageProps,
    opts: {
      accessTokenProps,
      router,
      updateWebSockets,
      setSubscriptionClient,
      createSubscriptionClientEvent,
    },
  })

  return (
    <ApolloProvider client={apollo}>
      <AmendableProvider resolvers={resolvers}>
        <ToastProvider bottom={3} left={3} right={3}>
          <NewConversationProvider>
            <NewMessagesProvider>
              <IsSignupCompletedModalShownProvider>
                <ModalProvider>
                  <IntervalProvider>
                    <Content
                      Component={Component}
                      pageProps={pageProps}
                      err={err}
                    />
                  </IntervalProvider>
                </ModalProvider>
              </IsSignupCompletedModalShownProvider>
            </NewMessagesProvider>
          </NewConversationProvider>
        </ToastProvider>
      </AmendableProvider>
    </ApolloProvider>
  )
}

const getCookies = ({ ctx }) => {
  if (ctx && ctx.req && ctx.req.headers.cookie) {
    return new Cookies(ctx.req.headers.cookie)
  }

  return new Cookies()
}

App.getInitialProps = async (appContext) => {
  const appProps = await NextApp.getInitialProps(appContext)
  const { Component, ctx } = appContext

  let pageProps = {}

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }

  const cookies = getCookies({ ctx })

  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      ...pageProps,
    },
    cookies,
    initialCurrentTime: dayjs().toISOString(),
  }
}

export default App

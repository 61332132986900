import { onError } from '@apollo/client/link/error';

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      console.log(`[GraphQL error]: ${JSON.stringify(graphQLErrors)}`);
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

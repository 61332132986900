import _ from 'lodash'
import { CloseCode } from 'graphql-ws'
import * as Sentry from '@sentry/node'

const handledErrorCodes = [CloseCode.Forbidden]
const handledErrorMessages = [
  'Socket closed with event 4403 Forbidden',
  'Socket closed with event 1001 Going away',
  'Socket closed with event 4499 Terminated', // ping timeout
  // 'Not Authorized!', // When trying to load a record you cannot access
]

export const onError =
  ({ addToast }) =>
  (error) => {
    if (_.includes(handledErrorCodes, error?.code)) return
    if (_.includes(handledErrorMessages, error?.message)) return

    if (error?.networkError?.isFailedRefreshToken) {
      return addToast({
        appearance: 'error',
        message: 'Log in to access your Atlasmic account',
      })
    }

    console.warn('Atlasmic unhandled error', error)
    Sentry.captureException(error)

    return addToast({
      appearance: 'error',
      message: 'We had a problem loading this page. Try again or contact us',
    })
  }

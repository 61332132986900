import dayjs from 'dayjs'

export const getRefreshToken = (opts) => {
  const accessTokenObject = opts.accessTokenProps.getAccessTokenObject()

  if (!accessTokenObject) {
    return null
  }

  const { refreshToken, refreshTokenExpiresAt } = accessTokenObject

  if (dayjs(refreshTokenExpiresAt).isBefore(dayjs())) {
    return null
  }

  return refreshToken
}

import _ from 'lodash'
import { useMemo } from 'react'
import merge from 'deepmerge'
import { createApolloClient } from './createApolloClient'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient

// everything is from https://github.com/vercel/next.js/tree/canary/examples/with-apollo
export function initializeApollo({ initialState = null, opts }) {
  const _apolloClient = apolloClient ?? createApolloClient(opts)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !_.isEqual(d, s))
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState({ client, pageProps }) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo({ pageProps, opts }) {
  const initialState = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(
    () => initializeApollo({ initialState, opts }),
    [initialState]
  )
  return store
}

import { useCallback, useMemo } from 'react'
import { CloseCode } from 'graphql-ws'
import { useApolloClient } from '@apollo/client'
import { useReconnect } from '@atlasmic/shared/hooks/subscriptionClients/useReconnect'
import { useOnDisconnect } from '@atlasmic/shared/hooks/subscriptionClients/useOnDisconnect'
import { useRetryTime } from '@atlasmic/shared/hooks/subscriptionClients/useRetryTime'
import { useOnRetryTimeEnded } from '@atlasmic/shared/hooks/subscriptionClients/useOnRetryTimeEnded'
import { useAccessToken } from '~hooks/auth/useAccessToken'
import { getNewAccessToken } from '~hooks/apollo/useApollo/createApolloClient/link/fullHttpLink/httpLink/getNewAccessToken'
import { useUpdateWebSockets } from '@atlasmic/shared/hooks/webSockets/useUpdateWebSockets'
import { useConnectionStatusToast } from '@atlasmic/shared/hooks/subscriptionClients/useConnectionStatusToast'
import { ConnectionStatusToast } from '@atlasmic/shared/components/connectionStatuses/ConnectionStatusToast'

export const useConnectionStatus = () => {
  const client = useApolloClient()
  const accessTokenProps = useAccessToken()
  const { updateWebSockets } = useUpdateWebSockets()

  const { retryTime, resetRetryTime, increment, resetIncrement } =
    useRetryTime()

  const { reconnect: execReconnect, isReconnecting } = useReconnect({
    onCompleted: () => {
      resetIncrement()
      client.refetchQueries({
        include: 'active',
      })
    },
  })

  const reconnect = useCallback(() => {
    resetRetryTime()
    execReconnect()
  }, [resetRetryTime, execReconnect])

  useOnDisconnect(({ latestSubscriptionClientEvent }) => {
    if (latestSubscriptionClientEvent.event.code === CloseCode.Forbidden) {
      getNewAccessToken({ accessTokenProps, updateWebSockets })()
      return
    }

    if (latestSubscriptionClientEvent.event.code === 1000) {
      return
    }

    increment()
  })

  useOnRetryTimeEnded({
    retryTime,
    onEnded: reconnect,
  })

  const toast = useMemo(
    () => (
      <ConnectionStatusToast
        isReconnecting={isReconnecting}
        retryTime={retryTime}
        reconnect={reconnect}
      />
    ),
    [isReconnecting, retryTime, reconnect]
  )

  useConnectionStatusToast(toast)

  return null
}

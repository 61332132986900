import React, { forwardRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'
import { useAmendable } from '@amendable/core'

const StyledComponent = styled.div((props) => props['data-css'])

export const Box = forwardRef(function Box(
  { component: Component = 'div', children, ...rest },
  ref
) {
  const { css, ...props } = useAmendable({
    ...rest,
  })

  return (
    <StyledComponent
      as={Component}
      ref={ref}
      {...(isEmpty(css) ? {} : { 'data-css': css })}
      {...props}
    >
      {children}
    </StyledComponent>
  )
})

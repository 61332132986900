import _ from 'lodash'

const url = ({ router }) => {
  if (_.includes(['/', '/login', '/signup'], router.pathname)) {
    return '/login'
  }

  const pathname = router.query.unauthRedirect || '/login'
  const redirect = encodeURIComponent(router.asPath)

  return `${pathname}?redirect=${redirect}`
}

export const onError = ({
  router,
}) => () => {
  if (typeof window === 'undefined') {
    return
  }

  console.log('Failed to fetch new access token')
  router.push(url({ router }))
}

import { useCookies } from 'react-cookie'
import { options } from './lib/options'
import { name } from './lib/name'
import { getAccessToken } from './lib/getAccessToken'
import { getAccessTokenObject } from './lib/getAccessTokenObject'

export const useAccessToken = () => {
  const [cookies, setCookie, removeCookie] = useCookies([name])

  return {
    getAccessToken: getAccessToken({ cookies }),
    getAccessTokenObject: getAccessTokenObject({ cookies }),
    setAccessToken: (token) => setCookie(name, JSON.stringify(token), options()),
    removeAccessToken: () => removeCookie(name, options()),
  }
}

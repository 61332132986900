import { ApolloClient } from '@apollo/client'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { sha256 } from 'crypto-hash'
import { link } from './link'
import { cache } from './cache'

const persistedQueriesLink = createPersistedQueryLink({ sha256 })

export const createApolloClient = (opts) =>
  new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: process.env.NEXT_PUBLIC_ENABLE_PERSISTED_QUERIES
      ? persistedQueriesLink.concat(link(opts))
      : link(opts),
    cache,
  })

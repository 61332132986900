import Cookies from 'js-cookie'
import { isBrowser } from '~utils/misc/isBrowser'
import { name } from './name'

export const getAccessTokenObject =
  ({ cookies }) =>
  () => {
    const cookie = isBrowser() ? Cookies.get(name) : cookies[name]
    if (!cookie) return null

    return JSON.parse(cookie)
  }

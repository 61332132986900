import _ from 'lodash'
import { page as gtagPage } from '~analytics/gtag/page'
import { page as facebookPixelPage } from '~analytics/facebookPixel/page'

export const page = ({
  pathname,
  skip = [],
}) => {
  if (!_.includes(skip, 'atlasmic')) {
    window.atlasmic({
      appType: 'TRACK',
      eventType: 'PAGE',
      eventProperties: [{
        slug: 'locationHref',
        value: `${window.location.origin}${pathname}`,
      }],
    })
  }

  gtagPage({ pathname })
  facebookPixelPage()
}

import { useState, useEffect } from 'react'
import { getMessaging, onMessage, isSupported } from 'firebase/messaging'
import { getFirebase } from '~utils/firebase/get'

export const useForegroundNotifications = () => {
  const [isFirebaseMessagingSupported, setIsFirebaseMessagingSupported] =
    useState(false)

  useEffect(() => {
    ;(async () => {
      setIsFirebaseMessagingSupported(await isSupported())
    })()
  }, [])

  useEffect(() => {
    if (!isFirebaseMessagingSupported) return

    const messaging = getMessaging(getFirebase())

    onMessage(messaging, (payload) => {
      console.log('Received message foreground', payload)

      const notification = new Notification(payload.data.title, {
        body: payload.data.body,
        icon: '/android-chrome-512x512.png',
        data: payload.data,
        tag: payload.data.tag,
      })

      notification.onclick = (event) => {
        event.preventDefault()
        window.open(payload.data.url, '_blank')
        notification.close()
      }
    })
  }, [isFirebaseMessagingSupported])

  return null
}

import { getAccessTokenObject } from './getAccessTokenObject'

export const getAccessToken = ({
  cookies,
}) => () => {
  const accessTokenObject = getAccessTokenObject({ cookies })()
  if (!accessTokenObject) return null

  return accessTokenObject.accessToken
}

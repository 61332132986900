import { useState } from 'react'
import { Provider } from './Context'

export const ModalProvider = ({
  children,
}) => {
  const [modal, setModal] = useState(null)

  return (
    <Provider
      value={{
        modal,
        setModal,
      }}
    >
      {children}
    </Provider>
  )
}

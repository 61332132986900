import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { customFetch } from '@atlasmic/shared/apollo/customFetch'
import { batchKey } from '@atlasmic/shared/apollo/batchKey'
import { getNewAccessToken } from './getNewAccessToken'
import { onError } from './onError'

export const httpLink = (opts) =>
  new BatchHttpLink({
    batchMax: 20,
    uri: process.env.NEXT_PUBLIC_API_URI,
    batchKey,
    fetch: customFetch({
      getAccessToken: opts.accessTokenProps.getAccessToken,
      getNewAccessToken: getNewAccessToken(opts),
      onError: onError(opts),
    }),
  })

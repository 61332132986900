import expandStyleShorthands from '@amendable/expand-style-shorthands';
import amendableColors from '@amendable/colors-polished';
import scale from '@amendable/scale';
import spacingAliases from '@amendable/spacing-aliases';
import breakpointsCss from '@amendable/breakpoints-css';
import inlinePropsCss from '@amendable/inline-props-css';
import { colors } from '@atlasmic/theme/colors';
import { borderRadiuses } from '@atlasmic/theme/borderRadiuses';
import { textStyles } from '@atlasmic/theme/textStyles';
import { borders } from '@atlasmic/theme/borders';
import { boxShadows } from '@atlasmic/theme/boxShadows';
import widths from './widths';

const widthsResolver = () => ({
  match: ['maxWidth'],
  resolve: ({ key, value }) => ({
    [key]: widths[value] || value,
  }),
})

const boxShadowsResolver = () => ({
  match: ['boxShadow'],
  resolve: ({ key, value }) => ({
    [key]: boxShadows[value] || value,
  }),
})

const borderRadiusesResolver = () => ({
  match: ['borderRadius', 'borderTopLeftRadius', 'borderTopRightRadius', 'borderBottomLeftRadius', 'borderBottomRightRadius'],
  resolve: ({ key, value }) => ({
    [key]: borderRadiuses[value] || value,
  }),
})

const bordersResolver = () => ({
  match: /border/,
  resolve: ({ key, value }) => ({
    [key]: borders[value] || value,
  }),
})

const textStylesResolver = () => ({
  match: ['textStyle'],
  resolve: ({ key, value }) => ({
    ...(textStyles[value] || { [key]: value }),
  }),
})

export default [
  bordersResolver(),
  expandStyleShorthands(),
  breakpointsCss(),
  textStylesResolver(),
  widthsResolver(),
  boxShadowsResolver(),
  borderRadiusesResolver(),
  amendableColors({ colors }),
  spacingAliases(),
  scale({ base: 8, calculation: ({ value, base }) => `${value * base}px` }),
  inlinePropsCss(),
];

import { setContext } from '@apollo/client/link/context'

export const authLink = (opts) => setContext((_, { headers }) => {
  if (!opts.accessTokenProps.getAccessToken()) return { headers }

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${opts.accessTokenProps.getAccessToken()}`,
    }
  }
})

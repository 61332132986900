import { split } from '@apollo/client'
import { createClient } from 'graphql-ws'
import { subscriptionClientOn } from '@atlasmic/shared/utils/subscriptionClients/subscriptionClientOn'
import { token } from '~utils/agentConnections/token'
import { isWs } from './isWs'
import { wsLink } from './wsLink'
import { fullHttpLink } from './fullHttpLink'

export const link = (opts) => {
  if (typeof window === 'undefined') {
    return fullHttpLink(opts)
  }

  const subscriptionClient = createClient({
    url: process.env.NEXT_PUBLIC_API_WS_URI,
    lazy: true,
    connectionParams: () => ({
      accessToken: opts.accessTokenProps.getAccessToken(),
      agentConnectionToken: token,
    }),
    retryAttempts: 0,
    shouldRetry: () => false,
    keepAlive: 10000,
    on: subscriptionClientOn({
      ...opts,
      getSubscriptionClient: () => subscriptionClient,
    }),
  })

  // TODO prevents warning of setState inside render
  setTimeout(() => opts.setSubscriptionClient(subscriptionClient), 0)

  return split(
    isWs,
    wsLink({ subscriptionClient, ...opts }),
    fullHttpLink({ subscriptionClient, ...opts })
  )
}

import _ from 'lodash'
import { InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { MessagesQuery } from '@atlasmic/shared/graphql/queries/messages/MessagesQuery'
import { EventPropertiesQuery } from '@atlasmic/shared/graphql/queries/eventProperties/EventPropertiesQuery'

const messagesRelayStylePagination = relayStylePagination(['conversationId'])

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        domainVerifications: relayStylePagination(['domainId']),
        messages: {
          ...messagesRelayStylePagination,
          merge: (existing, incoming, opts) => {
            // TODO: handle create new conversation first message
            const isFirstMessageMisbatch =
              !_.isEmpty(existing?.edges) && _.isEmpty(incoming?.edges)
            // TODO: make this better somehow by deduping batch requests in same batch
            const isFirstLoadMisbatch =
              !_.isEmpty(existing?.edges) &&
              incoming?.edges?.length === 1 &&
              existing.edges.length > 1

            if (isFirstMessageMisbatch || isFirstLoadMisbatch) return existing

            return messagesRelayStylePagination.merge(existing, incoming, opts)
          },
        },
        events: relayStylePagination(['userId']),
        conversations: relayStylePagination(['workspaceId', 'filter']),
        users: relayStylePagination([
          'workspaceId',
          'conversationId',
          'filter',
        ]),
        userPropertyConfigs: relayStylePagination(['workspaceId', 'slugNotIn']),
        userPropertiesByCreatedByTypeCreatedById: relayStylePagination([
          'createdByType',
          'createdById',
        ]),
        userPropertiesByUserIdUserPropertyConfigId: relayStylePagination([
          'userId',
          'userPropertyConfigId',
        ]),
        userPropertyConfig(_, { args, toReference }) {
          return toReference({
            __typename: 'UserPropertyConfig',
            workspaceId: args.workspaceId,
            slug: args.slug,
          })
        },
        conversation(_, { args, toReference }) {
          return toReference({
            __typename: 'Conversation',
            id: args.conversationId,
          })
        },
        workspace(_, { args, toReference }) {
          return toReference({
            __typename: 'Workspace',
            id: args.workspaceId,
          })
        },
        agent(_, { args, toReference }) {
          return toReference({
            __typename: 'Agent',
            id: args.agentId,
          })
        },
        user(_, { args, toReference }) {
          return toReference({
            __typename: 'User',
            id: args.userId,
          })
        },
        event(_, { args, toReference }) {
          return toReference({
            __typename: 'Event',
            id: args.eventId,
          })
        },
      },
    },
    Event: {
      fields: {
        eventProperties(name, { cache, readField }) {
          const result = cache.readQuery({
            query: EventPropertiesQuery,
            variables: {
              eventId: readField('id'),
            },
          })

          if (result) return result.eventProperties

          return {
            __typename: 'EventPropertyConnection',
            edges: [],
          }
        },
      },
    },
    UserPropertyConfig: {
      keyFields: ['workspaceId', 'slug'],
    },
    UserProperty: {
      keyFields: ['id'],
    },
    Conversation: {
      fields: {
        messages(name, { cache, readField }) {
          const result = cache.readQuery({
            query: MessagesQuery,
            variables: {
              conversationId: readField('id'),
              first: 1,
            },
          })

          if (result) return result.messages

          return {
            __typename: 'MessageConnection',
            edges: [],
          }
        },
      },
    },
  },
})

import { getMainDefinition } from '@apollo/client/utilities'

export const isWs = ({ query }) => {
  const definition = getMainDefinition(query);

  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
}
